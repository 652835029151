body{
    font-family: 'Gotham';
}

.hero-bg{
    background-image: url('../../../Assets/img/banner/new-banner.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
    /*width: 100%;*/
    /*height: 100vh;*/
}

.inspection-type-bg{
    background-image: url('../../../Assets/img/banner/banner7.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.features-bg{
    background-image: url('../../../Assets/img/banner/black_textured.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.features-gif-bg{
    background-image: url('../../../Assets/gif/phone 2 cropped reversed.gif');
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    /*background-attachment: fixed; !* Optional: Fixed background *!*/
}

.cta-bg{
    background-image: url('../../../Assets/img/banner/banner12.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.texture-dark-bg{
    background-image: url('../../../Assets/img/banner/black_textured.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.texture-light-green-bg{
    background-image: url('../../../Assets/img/banner/light green.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.texture-dark-green-bg{
    background-image: url('../../../Assets/img/banner/green.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.texture-white-bg{
    background-image: url('../../../Assets/img/banner/white.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.no-quotes-bg{
    background-image: url('../../../Assets/img/banner/banner14.png');
    background-size: contain;    
    background-position: center center;
    background-repeat: no-repeat;
    /*background-attachment: fixed; !* Optional: Fixed background *!*/
}

@media (max-width: 767px) {
    /* Your CSS styles for mobile screens */
    .no-quotes-bg{
        background-size: 20em 25em !important;
    }
}
  

.checkmates-bg{
    background-image: url('../../../Assets/img/banner/banner13.png');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    /*background-attachment: fixed; !* Optional: Fixed background *!*/
}

.how-it-works-one-bg{
    background-image: url('../../../Assets/img/banner/1.png');
    background-size: 25em 30em;
    background-position: 90% 30%;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.how-it-works-two-bg{
    background-image: url('../../../Assets/img/banner/2.png');
    background-size: 25em 30em;
    background-position: 90% 30%;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.how-it-works-three-bg{
    background-image: url('../../../Assets/img/banner/3.png');
    background-size: 25em 30em;
    background-position: 90% 30%;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.testimonial-bg{
    background-image: url('../../../Assets/img/banner/banner6.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /*background-attachment: fixed; !* Optional: Fixed background *!*/
}

.transparent-bg {
    /*margin-right: auto;*/
    /*margin-left: auto;*/
    box-shadow: 0 15px 25px rgba(49, 46, 46, 0.2);
    border-radius: 5px;
    backdrop-filter: blur(14px);
    background-color: rgba(33, 37, 41, 0.32);
}


.drop-shadow {
    filter: drop-shadow(1em 1.5em 1em rgba(0, 0, 0, 0.9)); /* Adjust shadow properties as needed */
}

.mt-3{
    margin-top: 3vh;
}
.mt-10{
    margin-top: 10vh;
}
.mt-17{
    margin-top: 17vh;
}
.mt-20{
    margin-top: 20vh;
}
.mt-25{
    margin-top: 25vh;
}
.h-10{
    min-height: 10vh;
}
.h-20{
    min-height: 20vh;
}
.h-30{
    min-height: 30vh;
}
.h-40{
    min-height: 40vh;
}
.h-50{
    min-height: 50vh;
}
.h-60{
    min-height: 60vh;
}
.h-70{
    min-height: 70vh;
}
.h-80{
    min-height: 80vh;
}
.h-90{
    min-height: 90vh;
}
.h-100{
    min-height: 100vh;
}

.hi-7 {
    height: 7em;
}

.hi-15 {
    height: 15em;
}