@font-face {
    font-family: 'Gotham';
    src: url('../../../Assets/font/Gotham/Gotham-Black.otf') format('otf'),
    url('../../../Assets/font/Gotham/Gotham-Bold.otf') format('otf'),
    url('../../../Assets/font/Gotham/GothamBold.ttf') format('ttf'),
    url('../../../Assets/font/Gotham/GothamBook.ttf') format('ttf'),
    url('../../../Assets/font/Gotham/Gotham-Light.otf') format('otf'),
    url('../../../Assets/font/Gotham/GothamLight.ttf') format('ttf'),
    url('../../../Assets/font/Gotham/GothamMedium.ttf') format('ttf'),
    url('../../../Assets/font/Gotham/Gotham-Thin.otf') format('otf');
}
body{
    font-family: Gotham;
}

.inspection-type-bg{
    background-image: url('../../../Assets/img/banner/banner7.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.features-bg{
    background-image: url('../../../Assets/img/banner/black_textured.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.cta-bg{
    background-image: url('../../../Assets/img/banner/banner12.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.texture-dark-bg{
    background-image: url('../../../Assets/img/banner/black_textured.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.texture-light-green-bg{
    background-image: url('../../../Assets/img/banner/light green.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.texture-dark-green-bg{
    background-image: url('../../../Assets/img/banner/green.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.texture-white-bg{
    background-image: url('../../../Assets/img/banner/white.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.texture-light-green-blob-bg{
    background-image: url('../../../Assets/blob/texture-light.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /*background-attachment: fixed; !* Optional: Fixed background *!*/
}

.texture-light-green-blob-2-bg{
    background-image: url('../../../Assets/blob/textured-light-2.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /*background-attachment: fixed; !* Optional: Fixed background *!*/
}

.how-it-works-one-bg{
    background-image: url('../../../Assets/img/banner/1.png');
    background-size: 25em 30em;
    background-position: 90% 30%;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.how-it-works-two-bg{
    background-image: url('../../../Assets/img/banner/2.png');
    background-size: 25em 30em;
    background-position: 90% 30%;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.how-it-works-three-bg{
    background-image: url('../../../Assets/img/banner/3.png');
    background-size: 25em 30em;
    background-position: 90% 30%;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
}

.testimonial-bg{
    background-image: url('../../../Assets/img/banner/banner6.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /*background-attachment: fixed; !* Optional: Fixed background *!*/
}

.transparent-bg {
    /*margin-right: auto;*/
    /*margin-left: auto;*/
    box-shadow: 0 15px 25px rgba(49, 46, 46, 0.2);
    border-radius: 5px;
    backdrop-filter: blur(14px);
    background-color: rgba(33, 37, 41, 0.32);
}

.hide-on-phone {
    display:none;
}

@media only screen and (min-width: 768px) {
    .hide-on-phone {
        display:block;
    }
}

.mt-10{
    margin-top: 10vh;
}
.mt-20{
    margin-top: 20vh;
}
.mt-25{
    margin-top: 25vh;
}
.h-10{
    min-height: 10vh;
}
.h-20{
    min-height: 20vh;
}
.h-30{
    min-height: 30vh;
}
.h-40{
    min-height: 40vh;
}
.h-50{
    min-height: 50vh;
}
.h-60{
    min-height: 60vh;
}
.h-70{
    min-height: 70vh;
}
.h-80{
    min-height: 80vh;
}
.h-90{
    min-height: 90vh;
}
.h-100{
    min-height: 100vh;
}

