@font-face {
  font-family: 'Gotham Black';
  src: url('./Assets/font/Gotham/Gotham-Black.otf') format('opentype');
}
@font-face {
  font-family: 'Gotham Bold';
  src: url('./Assets/font/Gotham/Gotham-Bold.otf') format('opentype'),
  url('./Assets/font/Gotham/GothamBold.ttf') format('ttf');
}
// @font-face {
//   font-family: 'Gotham Book';
//   src: url('./Assets/font/Gotham/GothamBook.ttf') format('truetype');
// }
@font-face {
  font-family: 'Gotham Book';
  src: url('./Assets/font/Gotham/gothambook-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Gotham Light';
  src: url('./Assets/font/Gotham/Gotham-Light.otf') format('opentype'),
  url('./Assets/font/Gotham/GothamLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Gotham Medium';
  src: url('./Assets/font/Gotham/GothamMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'Gotham Thin';
  src: url('./Assets/font/Gotham/Gotham-Thin.otf') format('opentype');
}

@import 'leaflet/dist/leaflet.css';

.gotham-black{
  font-family: 'Gotham Black', sans-serif!important;
}
.gotham-bold{
  font-family: 'Gotham Bold', sans-serif!important;
}
.gotham-book{
  font-family: 'Gotham Book', sans-serif!important;
}
.gotham-light{
  font-family: 'Gotham Light', sans-serif!important;
}
.gotham-medium{
  font-family: 'Gotham Medium', sans-serif!important;
}
.gotham-thin{
  font-family: 'Gotham Thin', sans-serif!important;
}

body {
  font-family: 'Gotham Book', sans-serif!important;
}
h1{
  font-family: 'Gotham Black', sans-serif!important;
}
h2{
  font-family: 'Gotham Black', sans-serif!important;
}
h3{
  font-family: 'Gotham Black', sans-serif!important;
}
h4{
  font-family: 'Gotham Medium', sans-serif!important;
}
h5{
  font-family: 'Gotham Medium', sans-serif!important;
}
h6{
  font-family: 'Gotham Medium', sans-serif!important;
}
p{
  font-family: 'Gotham Book', sans-serif!important;
}


$primaryColor: rgb(18, 18, 18);
$primaryColorFaded: rgba(2, 0, 36, 0.1);
$secondaryColor: rgb(118, 218, 161);
$secondaryColorFaded: rgb(118, 218, 161, 0.1);


$gradientColor1: $primaryColor;
$gradientColor2: rgb(0, 148, 64);
$gradientColor3: $secondaryColor;

body {
  margin: 0;
  font-family: Gotham, sans-serif, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden!important;
  }

code {
  font-family: Gotham, sans-serif, monospace;
}

.bg-custom-primary-faded {
  background: $gradientColor2 !important;
}

.bg-custom-secondary-faded {
  background: $secondaryColorFaded !important;
}

.bg-custom-gradient {
  background: linear-gradient(100deg, $gradientColor1, $gradientColor2, $gradientColor3);
}

/*animated gradient bg*/
.bg-custom-animated{
  background: linear-gradient(100deg, $gradientColor2, $gradientColor3);
  background-size: 1600% 1600%;

  -webkit-animation: AnimationName 27s ease infinite;
  -moz-animation: AnimationName 27s ease infinite;
  -o-animation: AnimationName 27s ease infinite;
  animation: AnimationName 27s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 11%}
  50%{background-position:100% 90%}
  100%{background-position:0% 11%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 11%}
  50%{background-position:100% 90%}
  100%{background-position:0% 11%}
}
@-o-keyframes AnimationName {
  0%{background-position:0% 11%}
  50%{background-position:100% 90%}
  100%{background-position:0% 11%}
}
@keyframes AnimationName {
  0%{background-position:0% 11%}
  50%{background-position:100% 90%}
  100%{background-position:0% 11%}
}

.bg-white {
  background: #ffffff;
}

.bg-custom-primary {
  background: $gradientColor2!important;
}

.bg-custom-dark {
  background: #191917!important;
}


.bg-custom-secondary {
  background: $secondaryColor!important;
}

.text-white {
  color: #fff;
}

.text-custom-primary {
  color: $primaryColor!important;
}

.text-custom-secondary {
  color: $secondaryColor;
}

.delete-btn:hover {
  color: #d60b0b;
}

.edit-btn:hover {
  color: $primaryColor;
}

.full-width{
  width: 100%;
}

.h-10{
  min-height: 10vh;
}
.h-20{
  min-height: 20vh;
}
.h-30{
  min-height: 30vh;
}
.h-35{
  min-height: 35vh;
}
.h-40{
  min-height: 40vh;
}
.h-50{
  min-height: 50vh;
}
.h-60{
  min-height: 60vh;
}
.h-70{
  min-height: 70vh;
}
.h-80{
  min-height: 80vh;
}
.h-100 {
  min-height: 100vh;
}

/* overwrite mui styles */
.MuiLoadingButton-loadingIndicator{
  color: white!important;
}

.MuiTablePagination-selectLabel {
  margin: 0px!important;
}

.MuiTablePagination-displayedRows {
  margin: 0px!important;
}


// overwrite css for scrollbars globally
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $primaryColor;
}
::-webkit-scrollbar-thumb:window-inactive {
  width: 6px;
  background-color: #F5F5F5;
}

.apexcharts-menu-open{
  color: #000!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.react-colorful {
  width: 100% !important;
}

.h-30{
  min-height: 30vh;
}
.h-40{
  min-height: 40vh;
}
.h-50{
  min-height: 50vh;
}
.h-60{
  min-height: 60vh;
}

.css-1pxa9xg-MuiAlert-message {
  overflow: hidden !important;
}

.full-upper-case {
  text-transform: uppercase !important;
}
